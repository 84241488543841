import ContactSupport from '../../components/ContactSupport';
import { useSettings } from '../../contexts/SettingsContext';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { IssueRow, StatusTab } from '../types';
import { Link } from 'react-router-dom';
import { inventoryItemPath, issuePath, orderPath, partPath, runViewPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeDisplay from '../../components/DateTimeDisplay';
import Label from '../../components/Label';
import { StatusType } from 'shared/lib/types/postgres/issues';
import { ITEM_REFERENCE_TYPE, ORDER_REFERENCE_TYPE, PART_REFERENCE_TYPE, UNLINKED_REFERENCE_TYPE } from '../constants';
import Grid, { DEFAULT_GROUP_ROW_HEIGHT_PX, GridColumn } from '../../elements/Grid';
import { SortColumn } from 'react-data-grid';
import useLatestParts from '../../manufacturing/hooks/useLatestParts';
import { getScrollToUrlParams } from 'shared/lib/scrollToUtil';
import { isClosedIssue, isOpenIssue, sortStatusColumn } from '../lib/issues';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { useNavState } from '../../contexts/NavContext';
import projectGroupCell from '../../elements/renderers/ProjectGroupCell';
import ProjectRenderer from '../../elements/renderers/ProjectRenderer';
import { projectGrouping } from '../../lib/gridUtils';
import { getPartIdForRevisionId } from '../../manufacturing/lib/parts';
import TagsDisplay from '../../components/Settings/Tags/TagsDisplay';
import useEntityTags from '../../hooks/useEntityTags';
import { EntityType, Tag } from 'shared/lib/types/api/settings/tags/models';

const ISSUE_NO_TAGS_ROW_HEIGHT = 50;
const ISSUE_WITH_TAGS_ROW_HEIGHT = 70;
const ROW_HEIGHT = (row?: IssueRow, getTags?: (entityId: string) => Tag[]) => {
  const tags = getTags?.(`${row?.id}`);
  return tags && tags.length > 0 ? ISSUE_WITH_TAGS_ROW_HEIGHT : ISSUE_NO_TAGS_ROW_HEIGHT;
};

interface IssuesGridProps {
  issues: Array<IssueRow>;
  selectedStatus: StatusTab;
  verticalPadding?: number;
  setSearchTerm: (searchTerm: string) => void;
  viewTab?: ViewTab;
  expandedProjectNames: ReadonlySet<string>;
  setExpandedProjectNames: Dispatch<SetStateAction<ReadonlySet<string>>>;
}

const IssuesGrid = ({
  issues,
  selectedStatus,
  verticalPadding = 0,
  setSearchTerm,
  viewTab = ViewTab.List,
  expandedProjectNames,
  setExpandedProjectNames,
}: IssuesGridProps) => {
  const { projectsFilter } = useNavState();
  const { currentTeamId } = useDatabaseServices();
  const { isIssuesEnabled } = useSettings();
  const { parts } = useLatestParts();
  const { getTagsForEntity } = useEntityTags({ entityType: EntityType.ISSUE });

  const grouping = useMemo(
    () => projectGrouping<IssueRow>({ viewTab, expandedProjectNames, setExpandedProjectNames }),
    [expandedProjectNames, setExpandedProjectNames, viewTab]
  );
  const rowHeightGetter = useMemo(() => {
    if (viewTab === ViewTab.Tree) {
      return ({ type, row }) => {
        if (type === 'GROUP') {
          return DEFAULT_GROUP_ROW_HEIGHT_PX;
        }
        return ROW_HEIGHT(row, getTagsForEntity);
      };
    }

    return (row) => {
      return ROW_HEIGHT(row, getTagsForEntity);
    };
  }, [viewTab, getTagsForEntity]);

  const revisionIdToPartId = useMemo(() => {
    if (!issues) {
      return {};
    }
    const partIssues = issues.filter((issue) => issue.referenceType === PART_REFERENCE_TYPE);
    const revisionIds = partIssues.map((issue) => issue.referenceId);
    const revisionIdsUnique: string[] = Array.from(new Set(revisionIds));

    const revIdToPartId = {};
    for (const revisionId of revisionIdsUnique) {
      const partId = getPartIdForRevisionId(revisionId, parts ?? []);
      if (partId) {
        revIdToPartId[revisionId] = partId;
      }
    }
    return revIdToPartId;
  }, [issues, parts]);

  const defaultSort = [
    {
      columnKey: 'createdAt',
      direction: 'DESC',
    },
  ] as Array<SortColumn>;

  const columns = useMemo(() => {
    const columns: Array<GridColumn<IssueRow>> = [
      {
        key: 'id',
        name: 'Issue',
        sortable: true,
        width: '20%',
        renderCell({ row }: { row: IssueRow }) {
          const link = issuePath(currentTeamId, `${row.id}`);
          const from = selectedStatus === StatusTab.Closed ? 'closed' : 'open';
          return (
            <div className="flex flex-row items-center gap-x-2">
              <div className="flex-auto flex flex-col gap-y-1 leading-4">
                <div className="flex flex-row items-center">
                  <Link to={{ pathname: link, state: { from } }} className=" text-blue-600">
                    {row.id}
                  </Link>
                  <div className="ml-2" />
                </div>
                <div className="flex flex-row items-center">
                  <Link to={{ pathname: link, state: { from } }} className=" text-gray-900 truncate">
                    {row.title}
                  </Link>
                </div>
                <TagsDisplay
                  tags={getTagsForEntity(`${row.id}`)}
                  maxVisibleTags={2}
                  onClick={(tag) => setSearchTerm(tag.name)}
                />
              </div>
            </div>
          );
        },
        comparator: (a: IssueRow, b: IssueRow) => +a.id - +b.id,
      },
      {
        key: 'projectName',
        name: 'Project',
        width: '12%',
        sortable: true,
        renderGroupCell: projectGroupCell,
        renderCell: ({ row }) => ProjectRenderer({ row, setSearchTerm }),
      },
      {
        key: 'sub_status',
        name: 'Status',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          if (!row.status) {
            return null;
          }
          if (row.status.status === StatusType.Closed && row.subStatus) {
            return <Label text={row.subStatus.label} color={row.subStatus.color} size="xs" />;
          }
          return <Label text={row.status.name} color={row.status.color} size="xs" />;
        },
        comparator: (a: IssueRow, b: IssueRow) => sortStatusColumn(a, b),
      },
      {
        key: 'severity',
        name: 'Severity',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          return row.severity && <Label text={row.severity.name} color={row.severity.color} size="xs" />;
        },
        comparator: (a: IssueRow, b: IssueRow) =>
          (a.severity?.id || Number.MAX_SAFE_INTEGER) - (b.severity?.id || Number.MAX_SAFE_INTEGER),
      },
      {
        key: 'assignee',
        name: 'Assignee',
        width: '15%',
        sortable: true,
      },
      {
        key: 'referenceType',
        name: 'Type',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          if (row.referenceType === UNLINKED_REFERENCE_TYPE) {
            return <></>;
          }
          return row.referenceType && <div className="capitalize">{row.referenceType}</div>;
        },
      },
      {
        key: 'createdAt',
        name: 'Created At',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          return (
            <span className="text-sm text-gray-600">
              <DateTimeDisplay timestamp={row.createdAt} wrap={false} hasTooltip={true} />
            </span>
          );
        },
      },
      {
        key: 'reference',
        name: 'Reference',
        width: '8%',
        sortable: false,
        headerCellClass: 'flex items-center justify-start',
        cellClass: 'flex items-center justify-center',
        renderCell({ row }: { row: IssueRow }) {
          if (row.referenceType === UNLINKED_REFERENCE_TYPE) {
            return <></>;
          }
          let link = '';
          if (row.runId) {
            link = `${runViewPath(currentTeamId, row.runId)}${
              row.referenceId ? getScrollToUrlParams({ id: row.referenceId, type: 'step' }) : ''
            }`;
          } else if (row.referenceId && row.referenceType === ITEM_REFERENCE_TYPE) {
            link = inventoryItemPath(currentTeamId, row.referenceId);
          } else if (row.referenceId && revisionIdToPartId && row.referenceType === PART_REFERENCE_TYPE) {
            link = partPath(currentTeamId, revisionIdToPartId[row.referenceId], row.referenceId);
          } else if (row.referenceId && row.referenceType === ORDER_REFERENCE_TYPE) {
            link = orderPath(currentTeamId, row.referenceId);
          }
          return (
            <Link to={link} className="text-blue-600 hover:underline">
              <FontAwesomeIcon className="" icon="link" />
            </Link>
          );
        },
      },
    ];
    return columns;
  }, [currentTeamId, revisionIdToPartId, selectedStatus, setSearchTerm, getTagsForEntity]);

  const rows = useMemo(() => {
    const issueList: IssueRow[] =
      issues?.filter((issue) => {
        if (selectedStatus === StatusTab.Open) {
          return isOpenIssue(issue);
        }
        return isClosedIssue(issue);
      }) ?? [];
    return issueList;
  }, [issues, selectedStatus]);

  const gridKey = useMemo(() => `${selectedStatus}-${projectsFilter?.project.id}`, [projectsFilter, selectedStatus]);

  if (!(isIssuesEnabled && isIssuesEnabled())) {
    return <ContactSupport />;
  }

  return (
    <Grid<IssueRow>
      gridKey={gridKey}
      columns={columns}
      rows={rows}
      rowHeight={rowHeightGetter}
      usedVerticalSpace={verticalPadding}
      emptyRowMessage="No Issues Found"
      defaultSort={defaultSort}
      rowGrouping={grouping}
    />
  );
};

export default IssuesGrid;
