import React, { useState, useMemo, useEffect } from 'react';
import { ACCESS } from 'shared/lib/constants/permissions';
import useProjects from '../../hooks/useProjects';
import {
  TreeSelect,
  TreeSelectChangeEvent,
  TreeSelectExpandedKeysType,
  TreeSelectExpandedEvent,
} from 'primereact/treeselect';
import { getProjectsTreeFlat } from '../../lib/projectTreeUtil';
import { useNavState } from '../../contexts/NavContext';

interface ProjectsEditSelectProps {
  value?: string;
  onUpdateProject: (projectId?: string) => void;
  projectAccessLevel?: ACCESS;
}

const ProjectsEditSelect = ({
  value,
  onUpdateProject,
  projectAccessLevel = ACCESS.EDITOR,
}: ProjectsEditSelectProps) => {
  const { projectsFilter } = useNavState();
  const { getProjectsForAccessLevel } = useProjects();
  const projects = getProjectsForAccessLevel({ projectAccessLevel });
  const [expandedKeys, setExpandedKeys] = useState<TreeSelectExpandedKeysType>({});

  // Expand the selected project by default
  useEffect(() => {
    if (projectsFilter) {
      setExpandedKeys({ [projectsFilter.project.id]: true });
      return;
    }
    setExpandedKeys({});
  }, [projectsFilter]);

  const onToggle = (e: TreeSelectExpandedEvent): void => {
    setExpandedKeys(e.value);
  };

  const options = useMemo(() => {
    if (!projectsFilter || projectsFilter.subprojects.length < 1) {
      return getProjectsTreeFlat(projects);
    }

    // If the project has subprojects, show its subprojects as options
    const tree = getProjectsTreeFlat(projects);
    const node = tree.find((project) => project.id === projectsFilter.project.id);
    if (!node) {
      return [];
    }
    return [node];
  }, [projects, projectsFilter]);

  const disabled = Boolean(projectsFilter && projectsFilter.subprojects.length < 1);

  const clearProject = () => onUpdateProject();

  const onChange = (e: TreeSelectChangeEvent) => {
    const projectId = e.target.value;
    if (!projectId) {
      clearProject();
      return;
    }
    onUpdateProject(projectId as string);
  };

  // Don't allow clear if project selected in navigation
  const showClear = !projectsFilter;

  return (
    <div className="grow w-full">
      <div className="grow relative">
        <TreeSelect
          aria-label="Project Context Selector"
          placeholder="Select project"
          options={options}
          onChange={onChange}
          value={value}
          pt={{
            root: { className: 'shadow-none flex items-center' },
            panel: { className: `w-64 break-all overflow-y-auto unobtrusive-sidebar` },
          }}
          expandedKeys={expandedKeys}
          onToggle={onToggle}
          disabled={disabled}
          showClear={showClear}
          filter
        />
      </div>
    </div>
  );
};

export default React.memo(ProjectsEditSelect);
