import { Project, Projects } from 'shared/lib/types/couch/settings';
import { FilterOption } from '../components/lib/SearchFilter';

export const DEFAULT_PROJECT_ID = 'default';
export const DEFAULT_PROJECT_NAME = 'Default';

export type ProjectsFilter = {
  project: ProjectStub;
  subprojects: Array<ProjectStub>;
  projectIds: Array<string>; // includes projects and subprojects
};

type ProjectStub = {
  id: string;
  name: string;
};

export type ItemWithProject = {
  project_id?: string | null;
  projectId?: string | null;
};

const projectUtil = {
  isProjectCodeUnique(
    project: Project,
    projectsMap?: Record<string, Project>
  ): boolean {
    if (!projectsMap) {
      return true;
    }

    const projectIds = Object.keys(projectsMap);

    if (!projectIds.length) {
      return true;
    }

    return !projectIds.some((projectId) => {
      // Ignore the same project.
      if (projectId === project.id) {
        return false;
      }

      const projectCompare = projectsMap[projectId];

      /**
       * Legacy project objects do not have the code property.
       * If the code property is undefined in either project or
       * the project that it is being compared with, return false.
       */
      if (!projectCompare.code || !project.code) {
        return false;
      }

      return projectCompare.code.toLowerCase() === project.code.toLowerCase();
    });
  },
  getProjectName(
    projects: Projects | null,
    projectId?: string | null
  ): string | undefined {
    if (!projectId) {
      return undefined;
    }

    if (!projects?.projects?.[projectId]) {
      return undefined;
    }

    return projects.projects[projectId].name;
  },

  getProjectNames(
    keys: ReadonlySet<string | null>,
    projects: Projects | null
  ): Set<string> {
    const projectNames: Set<string> = new Set<string>();
    keys.forEach((id) => {
      if (id === null) {
        projectNames.add('Unassigned');
      } else {
        if (projects && projects.projects && projects.projects[id]) {
          projectNames.add(projects.projects[id].name);
        }
      }
    });
    return projectNames;
  },

  getOptionFromProject(project: Project | ProjectStub): FilterOption<string> {
    return {
      id: project.id,
      label: project.name,
    };
  },

  toProjectsArray(projects: Projects | null): Array<Project> {
    if (!projects || !projects.projects) {
      return [];
    }
    return Object.values(projects.projects);
  },

  getActiveParentProjects(projects: Array<Project>): Array<Project> {
    const activeProjects = projectUtil.getActiveProjects(projects);
    return activeProjects.filter((p) => !p.parent_id);
  },

  getArchivedProjects(projects: Array<Project>): Array<Project> {
    return projects.filter((project) => project.project_state === 'archived');
  },

  getActiveProjectIds(projects: Array<Project>): Array<string> {
    const activeProjects = projectUtil.getActiveProjects(projects);
    return activeProjects.map((project) => project.id);
  },

  getActiveSubprojects(
    projectId: string,
    projects: Array<Project>
  ): Array<Project> {
    const activeProjects = projectUtil.getActiveProjects(projects);
    return activeProjects.filter((p) => p.parent_id === projectId);
  },

  getActiveProjects(projects: Array<Project>): Array<Project> {
    return projects.filter((p) => p.project_state === 'active');
  },

  getArchivedSubprojects(
    projectId: string,
    projects: Array<Project>
  ): Array<Project> {
    const activeProjects = projectUtil.getArchivedProjects(projects);
    return activeProjects.filter((project) => project.parent_id === projectId);
  },

  getActiveSubprojectIds(
    projectId: string,
    projects: Array<Project>
  ): Array<string> {
    const activeSubprojects = projectUtil.getActiveSubprojects(
      projectId,
      projects
    );
    return activeSubprojects.map((project) => project.id);
  },

  getProjectStub(
    projectId: string,
    projects: Array<Project>
  ): ProjectStub | null {
    const project = projects.find((p) => p.id === projectId);
    if (!project) {
      return null;
    }
    return {
      id: project.id,
      name: project.name,
    };
  },

  filterByProject<T extends ItemWithProject>(
    items: Array<T>,
    projectsFilter: ProjectsFilter
  ): Array<T> {
    return items.filter((item) =>
      projectUtil.itemInProject(item, projectsFilter)
    );
  },

  itemInProject<T extends ItemWithProject>(
    item: T,
    projectsFilter: ProjectsFilter
  ): boolean {
    if (item.project_id) {
      return projectsFilter.projectIds.includes(item.project_id);
    }
    if (item.projectId) {
      return projectsFilter.projectIds.includes(item.projectId);
    }
    return false;
  },

  isParentProject(
    projectId: string,
    projects: Array<Project>
  ): boolean | undefined {
    const project = projects.find((project) => project.id === projectId);
    if (!project) {
      return undefined;
    }
    return !project.parent_id;
  },

  filterByActive<T extends ItemWithProject>(
    items: Array<T>,
    projects: Array<Project>
  ): Array<T> {
    const activeProjectIds = projectUtil.getActiveProjectIds(projects);
    const filtered: Array<T> = [];
    for (const item of items) {
      const projectId = item.project_id || item.projectId;
      if (!projectId || activeProjectIds.includes(projectId)) {
        filtered.push(item);
      }
    }
    return filtered;
  },
};

export default projectUtil;
