import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import NotFound from '../components/NotFound';
import { useSettings } from '../contexts/SettingsContext';

import ProjectUsersService from '../api/projects';
import { ProjectUserRoles, UserFormUser } from '../components/Settings/types';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import apm from '../lib/apm';
import { PERM } from '../lib/auth';
import REFRESH_TRY_AGAIN_MESSAGE from '../lib/messages';
import { homePath, projectSettingsPath, teamSettingsProjectsPath } from '../lib/pathUtil';
import SidebarLayout from '../elements/SidebarLayout';
import GenericToolbar from '../components/Toolbar/Toolbar';
import RightSide from '../components/Toolbar/RightSide';
import { Divider } from '@mui/material';
import BackToLink from '../components/Toolbar/BackToLink';
import LeftSide from '../components/Toolbar/LeftSide';
import { Project } from 'shared/lib/types/couch/settings';
import Field, { FieldDefinition } from '../components/EntityDetail/Field';
import { MenuContextAction } from '../components/MenuContext';
import MoreActionsMenu from '../components/Toolbar/MoreActionsMenu';
import { UserRole } from 'shared/lib/types/settings';
import { capitalizeFirstLetter } from 'shared/lib/text';
import Button, { BUTTON_TYPES } from '../components/Button';
import NewProjectModal from '../settings/NewProjectModal';
import UserId from '../elements/UserId';
import Icon from '../elements/Icon';
import EditProjectModal from '../settings/EditProjectModal';
import NewUserProjectModal from '../settings/NewUserProjectModal';
import Tooltip from '../elements/Tooltip';
import ProjectUsersTable from '../components/Users/ProjectUsersTable';
import projectUtil from '../lib/projectUtil';
import { faFolderClosed, faFolderOpen } from '@fortawesome/free-solid-svg-icons';

const MAX_CHARS_CODE = 15;
const MAX_CHARS_DESCRIPITON = 100;
const REMOVE_USERS = 'Are you sure you want to remove user(s) from the project?';

enum StatusTab {
  Active = 'active',
  Archived = 'archived',
}

const ProjectDetailSettings = () => {
  const { auth } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [projectUsers, setProjectUsers] = useState<ProjectUserRoles[] | null>(null);
  const { services, currentTeamId } = useDatabaseServices();
  const history = useHistory();
  const { users, projects: projectsDoc, operatorRoles, isSubProjectsEnabled } = useSettings();
  const projects = projectUtil.toProjectsArray(projectsDoc);
  const project = useMemo(() => projectsDoc?.projects[id], [id, projectsDoc?.projects]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userRoles, setUserRoles] = useState<Array<UserRole>>([]);
  const [selectedUsers, setSelectedUsers] = useState<Array<UserFormUser>>([]);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);

  const rootParentProjectId = useMemo(() => {
    if (!project) {
      return null;
    }
    return project.parent_id ?? project.id;
  }, [project]);

  const rootParentProject = useMemo(() => {
    const found = projects.find((project) => project.id === rootParentProjectId);
    if (!found) {
      return null;
    }
    return found;
  }, [rootParentProjectId, projects]);

  const children = useMemo(() => {
    if (!project) {
      return [];
    }
    if (project.project_state === StatusTab.Archived) {
      if (rootParentProjectId === project.id) {
        return projectUtil.getArchivedSubprojects(project.id, projects);
      }
      return [project];
    }
    if (project.parent_id) {
      return projectUtil.getActiveSubprojects(project.parent_id, projects);
    }
    return projectUtil.getActiveSubprojects(project.id, projects);
  }, [project, projects, rootParentProjectId]);

  const closeNewUserModalFunc = useCallback(() => {
    setShowNewUserModal(false);
  }, []);

  const onAddNewProjectUser = useCallback(() => {
    setShowNewUserModal(true);
  }, []);

  useEffect(() => {
    services.roles
      .listRoles()
      .then((workspaceRoles) => {
        setUserRoles(workspaceRoles);
      })
      .catch((err) => apm.captureError(err));
  }, [services.roles]);

  const projectUserService = useMemo(() => {
    if (!currentTeamId || !id) {
      return null;
    }
    return new ProjectUsersService(currentTeamId, id);
  }, [currentTeamId, id]);

  useEffect(() => {
    if (!projectUserService) {
      return;
    }
    projectUserService
      .listProjectUsers()
      .then((users) => setProjectUsers(users))
      .catch((err) => apm.captureError(err));
  }, [projectUserService, showNewUserModal]);

  const enableRemovingUsers = useCallback(() => {
    return selectedUsers?.length !== 0 && !selectedUsers.some((user) => user.workspace_role);
  }, [selectedUsers]);

  const deleteUser = useCallback(
    async (userId) => {
      let retPromise;

      await projectUserService
        ?.deleteProjectUser([userId])
        .then(() => {
          retPromise = Promise.resolve('User Removed');
        })
        .catch(() => Promise.reject(REFRESH_TRY_AGAIN_MESSAGE));

      // Fetch users again to re-render the table
      try {
        const users = await projectUserService?.listProjectUsers();
        users && setProjectUsers(users);
      } catch {
        /*
         * A user could delete their own permissions to the project, if this happens, the request to list the project
         * users will fail, and they should be redirected away from the page.
         */
        history.push(homePath(currentTeamId));
        history.goForward();
        return Promise.reject(REFRESH_TRY_AGAIN_MESSAGE);
      }
      return retPromise;
    },
    [history, projectUserService, currentTeamId]
  );

  const save = useCallback(
    async (updatedUser, isNewUser) => {
      let retPromise;
      try {
        await projectUserService?.upsertProjectUser(updatedUser.id, updatedUser.project_roles);
        retPromise = Promise.resolve('Save successful.');
      } catch {
        return Promise.reject(REFRESH_TRY_AGAIN_MESSAGE);
      }

      // Fetch users again to re-render the table
      try {
        const users = await projectUserService?.listProjectUsers();
        users && setProjectUsers(users);
      } catch {
        /*
         * A user could potentially lower their own permissions to the point that they don't have
         * roles to the project settings anymore, if this happens, the request to list the project
         * users will fail, and they should be redirected away from the page.
         */
        setShowNewUserModal(false);
        history.push(homePath(currentTeamId));
        history.goForward();
        return Promise.reject(REFRESH_TRY_AGAIN_MESSAGE);
      }
      setShowNewUserModal(false);
      return retPromise;
    },
    [history, projectUserService, currentTeamId, setShowNewUserModal]
  );

  // Returns true if user has permission to edit users
  const canEdit = useMemo(() => auth.hasPermission(PERM.USERS_EDIT, id), [auth, id]);

  const onDeleteUser = useCallback(() => {
    if (!selectedUsers || selectedUsers.length === 0) return;

    return window.confirm(REMOVE_USERS) && Promise.all(selectedUsers.map((user) => deleteUser(user.id.toLowerCase())));
  }, [selectedUsers, deleteUser]);

  const navigateToSettings = teamSettingsProjectsPath(currentTeamId);

  const closeModalFunc = useCallback(async () => {
    setShowEditModal(false);
  }, [setShowEditModal]);

  const closeNewSubProjectModal = useCallback(async () => {
    setShowNewModal(false);
  }, [setShowNewModal]);

  const fields: Array<FieldDefinition<Project>> = [
    {
      label: 'Code',
      formatter: (project) => (
        <div className="flex items-center gap-x-1">
          <Tooltip content={`${project.code}`}>
            <div>
              {project.code.length > MAX_CHARS_CODE ? `${project.code.substring(0, MAX_CHARS_CODE)}...` : project.code}
            </div>
          </Tooltip>
        </div>
      ),
    },
    ...(project?.created_at && project?.created_user_id
      ? [
          {
            label: 'Created by',
            formatter: (project) => (
              <div className="-mt-1">
                <UserId userId={project.created_user_id} timestamp={project.created_at} />
              </div>
            ),
          },
        ]
      : []),
    ...((project?.updated_at && project?.updated_user_id) || (project?.created_at && project?.created_user_id)
      ? [
          {
            label: 'Updated by',
            formatter: (project) => {
              const userId = project.updated_user_id || project.created_user_id || '';
              const timestamp = project.updated_at || project.created_at || '';

              if (!userId || !timestamp) return <div></div>;

              return (
                <div className="-mt-1">
                  <UserId userId={userId} timestamp={timestamp} />
                </div>
              );
            },
          },
        ]
      : []),
    {
      label: 'Status',
      formatter: (project) => (
        <div className="flex items-center gap-x-1">{capitalizeFirstLetter(project.project_state ?? '')}</div>
      ),
    },
  ];

  const menuActions: Array<MenuContextAction> = [
    {
      type: 'label',
      label: 'Edit',
      data: {
        title: 'Edit',
        disabled: undefined,
        icon: 'pencil',
        onClick: () => setShowEditModal(true),
      },
    },
  ];

  const isArchivedParent = project?.parent_id !== undefined && rootParentProject?.project_state === StatusTab.Archived;
  const isRootSameAsProject = rootParentProject?.id === project?.id;

  if ((!isArchivedParent || isRootSameAsProject) && isSubProjectsEnabled()) {
    menuActions.push({
      type: 'label',
      label: project?.project_state === 'active' ? 'Archive' : 'Unarchive',
      data: {
        title: project?.project_state === 'active' ? 'Archive' : 'Unarchive',
        disabled: undefined,
        textColor: project?.project_state === 'active' ? 'text-red-500' : 'text-gray-500',
        icon: project?.project_state === 'active' ? 'box' : 'box-open',
        onClick: async () => {
          await services.settings.updateProject(
            {
              project_state: project?.project_state === 'active' ? 'archived' : 'active',
            },
            project?.id
          );

          project?.project_state === 'active' && history.push(teamSettingsProjectsPath(currentTeamId));
        },
      },
    });
  }

  const activeUsers: Array<UserFormUser> = useMemo(() => {
    if (!users?.users || !projectsDoc?.projects) {
      return [];
    }

    const projectUserIds = new Set(projectUsers?.map((user) => user.id));

    const filteredUsers = Object.keys(users.users)
      .filter((email) => projectUserIds.has(email))
      .reduce((acc, email) => {
        acc[email] = users.users[email];
        return acc;
      }, {});

    const activeUsers = Object.keys(filteredUsers)
      .map((userId) => {
        const userObject = users.users[userId];
        const projectRoles: Record<string, (typeof userRoles)[number][]> = {};

        for (const projectId of Object.keys(projectsDoc.projects)) {
          const roles = userObject.project_roles?.[projectId] || userObject.workspace_roles?.[currentTeamId];

          if (roles) {
            projectRoles[projectId] = roles
              .map((role) => userRoles.find((userRole) => role === userRole.name) || null)
              .filter(Boolean) as (typeof userRoles)[number][];
          }
        }

        // Extract workspace role if it exists
        const workspaceRolesForTeam = userObject.workspace_roles?.[currentTeamId];
        const workspaceRole = workspaceRolesForTeam?.[0] ? { workspace_role: workspaceRolesForTeam[0] } : {};

        // Extract operator roles
        const operatorRoles = userObject.operator_roles;

        // Filter project roles
        let projectRolesFiltered: { [project_id: string]: UserRole[] } | undefined;

        // Check if there are any project roles
        const hasProjectRoles = Object.keys(projectRoles).length > 0;

        if (hasProjectRoles) {
          // Filter roles only for the current project

          // Get all project role entries
          const projectRoleEntries = Object.entries(projectRoles);

          // Filter only the roles that match the current project's ID
          const matchingProjectRoles = projectRoleEntries.filter(([id]) => id === project?.id);

          // Map the filtered roles into the expected structure
          const mappedProjectRoles = matchingProjectRoles.map(([id, roles]) => [id, roles as UserRole[]]);

          // Convert the mapped array back into an object
          const filteredRoles = Object.fromEntries(mappedProjectRoles);

          // Assign filtered roles only if there are valid entries
          if (Object.keys(filteredRoles).length > 0) {
            projectRolesFiltered = filteredRoles;
          }
        }

        // Construct and return the final object
        return {
          id: userId,
          ...workspaceRole,
          operator_roles: operatorRoles,
          project_roles: projectRolesFiltered,
        };
      })
      .sort((a, b) => a.id.localeCompare(b.id))
      .filter((user) => user.project_roles !== undefined && Object.keys(user.project_roles).length > 0)
      .filter((activeUser) => projectUsers?.some((projectUser) => projectUser.id === activeUser.id));

    return activeUsers;
  }, [currentTeamId, projectsDoc?.projects, userRoles, users?.users, projectUsers, project?.id]);

  const operatorDescriptionByKey = useMemo(() => {
    if (!operatorRoles || !operatorRoles.operators) {
      return {};
    }

    const descriptions = {};

    operatorRoles.operators.forEach((operator) => {
      descriptions[operator.code] = `${operator.code} - ${operator.name}`;
    });

    return descriptions;
  }, [operatorRoles]);

  const handleAddSubproject = () => {
    setShowNewModal(true);
  };

  const numSelectedUsers = useMemo(() => selectedUsers.length, [selectedUsers.length]);

  const pluralizeSelectedUsers = useMemo(() => (numSelectedUsers !== 1 ? 's' : ''), [numSelectedUsers]);

  if (!project) {
    return <NotFound />;
  }

  return (
    <div className="flex flex-col flex-grow">
      {/* Project Banner*/}
      <GenericToolbar>
        <LeftSide>
          <BackToLink to={navigateToSettings} name="Projects" />
          <Divider />
        </LeftSide>
        <RightSide>
          {canEdit && !project.parent_id && (
            <div className="flex  h-[32px] ">
              <Button type={BUTTON_TYPES.PRIMARY} leadingIcon="plus" onClick={onAddNewProjectUser}>
                Add User
              </Button>
            </div>
          )}
          <MoreActionsMenu actions={menuActions} label="Projects Menu" />
        </RightSide>
      </GenericToolbar>

      <SidebarLayout paddingTop="">
        <SidebarLayout.Sidebar>
          <div style={{ height: `calc(100% - 10px)` }} className="flex flex-col justify-between overflow-hidden">
            <div
              className={`flex flex-col overflow-hidden overflow-x-hidden 'min-h-[50%]'
                }`}
            >
              <strong className="flex text-lg pt-1">Project {project.name}</strong>
              <div className="text-sm text-gray-600">
                {project.description && project.description.length > MAX_CHARS_DESCRIPITON
                  ? `${project.description.substring(0, MAX_CHARS_DESCRIPITON)}...`
                  : project.description}
              </div>
              <div
                role="region"
                aria-label="Project Info"
                className="flex flex-col grow overflow-y-auto w-full py-2 pl-1"
              >
                <div className="flex flex-col gap-y-4">
                  {fields.map((field) => (
                    <Field
                      visible={field.visible}
                      fullWidth={field.fullWidth}
                      key={field.label}
                      label={field.label}
                      formatter={field.formatter(project)}
                      isEditing={true}
                      editor={field.editor ? field.editor(project) : field.formatter(project)}
                    />
                  ))}
                </div>
              </div>
              <div className="border-t w-full border-gray-300 my-2"></div>
              <strong className="flex text-lg">Project Tree</strong>

              <div className="p-2">
                <Link to={projectSettingsPath(currentTeamId, rootParentProject?.id as string)}>
                  <div
                    className={`p-1 cursor-pointer text-lg rounded-md   ${
                      rootParentProject?.id === project.id ? 'bg-blue-100' : ''
                    }`}
                  >
                    <Icon element={faFolderOpen} className="text-slate-600 pr-1" />
                    {rootParentProject?.name}
                  </div>
                </Link>

                {children.map((child) => (
                  <Link to={projectSettingsPath(currentTeamId, child?.id as string)}>
                    <div
                      className={`p-1 pl-5 cursor-pointer text-lg rounded-md   ${
                        child?.id === project.id ? 'bg-blue-100' : ''
                      }`}
                    >
                      <Icon element={faFolderClosed} className="text-slate-600 pr-1" />
                      {child?.name}
                    </div>
                  </Link>
                ))}

                {!project.parent_id && isSubProjectsEnabled() && project.project_state !== StatusTab.Archived && (
                  <div className="p-1 pl-3">
                    <Button type="tertiary" size="sm" leadingIcon="plus-circle" onClick={handleAddSubproject}>
                      New Subproject
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SidebarLayout.Sidebar>
        <SidebarLayout.Content>
          <>
            {showNewUserModal && (
              <NewUserProjectModal
                closeModal={closeNewUserModalFunc}
                onProjectUserSaved={save}
                projectUsers={projectUsers}
                selectedUsers={selectedUsers}
              />
            )}

            {showNewModal && <NewProjectModal closeModal={() => closeNewSubProjectModal()} parent_project={project} />}

            <div className="flex flex-col gap-y-2 p-4">
              {showEditModal && (
                <EditProjectModal
                  closeModal={() => closeModalFunc()}
                  project={project}
                  parent_project={rootParentProject}
                />
              )}
              Users
              <div>
                <div className="flex w-full -mb-1  items-center divide-x">
                  <span className="text-gray-400 p-2">
                    {numSelectedUsers} user{pluralizeSelectedUsers} selected
                  </span>
                  {canEdit && !project.parent_id && (
                    <div className="flex">
                      <Button
                        type={BUTTON_TYPES.TERTIARY}
                        title="Edit User from Project"
                        onClick={onAddNewProjectUser}
                        isDisabled={selectedUsers.length !== 1}
                        leadingIcon="pencil"
                      ></Button>
                    </div>
                  )}
                  {canEdit && !project.parent_id && (
                    <div className="flex">
                      <Button
                        type={BUTTON_TYPES.TERTIARY}
                        title="Remove User from Project"
                        onClick={onDeleteUser}
                        isDisabled={!enableRemovingUsers()}
                        leadingIcon="trash"
                      ></Button>
                    </div>
                  )}
                </div>
                <ProjectUsersTable
                  visibleUsers={activeUsers}
                  allUsers={activeUsers}
                  projects={Object.values(projectsDoc?.projects ?? {})}
                  operatorDescriptionByKey={operatorDescriptionByKey}
                  setSelectedUsers={setSelectedUsers}
                  showCheckboxes={canEdit && !project.parent_id}
                />
              </div>
            </div>
          </>
        </SidebarLayout.Content>
      </SidebarLayout>
    </div>
  );
};

export default React.memo(ProjectDetailSettings);
