import { useEffect, useMemo, useState } from 'react';
import { Column, RowHeightArgs } from 'react-data-grid';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { RunMetadata } from 'shared/lib/types/views/procedures';
import { RowWithProjectName } from '../../../components/Home/GridExpandCollapseButton';
import renderDateTime from '../../../components/Home/Renderers/DateTime';
import renderParticipants from '../../../components/Home/Renderers/Participants';
import renderRunStatus from '../../../components/Home/Renderers/Status';
import LoadingScreen from '../../../components/LoadingScreen';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { useNavState } from '../../../contexts/NavContext';
import { useSettings } from '../../../contexts/SettingsContext';
import Grid, { DEFAULT_GROUP_ROW_HEIGHT_PX } from '../../../elements/Grid';
import projectGroupCell from '../../../elements/renderers/ProjectGroupCell';
import ProjectRenderer from '../../../elements/renderers/ProjectRenderer';
import RunNameLinkRenderer from '../../../elements/renderers/RunNameLinkRenderer';
import {
  NO_TAGS_ROW_HEIGHT,
  TAGS_ROW_HIGHT,
  filterByField,
  filterBySearchTerm,
  projectGrouping,
} from '../../../lib/gridUtils';
import { runViewPath } from '../../../lib/pathUtil';
import projectUtil from '../../../lib/projectUtil';
import { PLAN_VERTICAL_PADDING } from '../../screens/Plans';

type PlanRow = RowWithProjectName & RunMetadata;

export interface PlansGridProps {
  searchTerm?: string;
  setSearchTerm: (searchTerm: string) => void;
  selectedProjects?: ReadonlySet<string | null>;
  viewTab?: ViewTab;
}

const EndedPlansGrid = ({
  searchTerm = '',
  setSearchTerm,
  selectedProjects,
  viewTab = ViewTab.List,
}: PlansGridProps) => {
  const { projectsFilter, filterByProject, persistedView } = useNavState();
  const { projects } = useSettings();
  const { services, currentTeamId } = useDatabaseServices();
  const { expandedProjectNames, setExpandedProjectNames } = persistedView;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [completedTestRuns, setCompletedTestRuns] = useState<Array<PlanRow>>([]);

  const grouping = useMemo(
    () => projectGrouping<PlanRow>({ viewTab, expandedProjectNames, setExpandedProjectNames }),
    [expandedProjectNames, setExpandedProjectNames, viewTab]
  );
  const rowHeightGetter = useMemo(() => {
    const nonGroupedHeightGetter = (row: PlanRow) => (row?.run_tags?.length ? TAGS_ROW_HIGHT : NO_TAGS_ROW_HEIGHT);
    if (viewTab === ViewTab.Tree) {
      return ({ type, row }: RowHeightArgs<PlanRow>) => {
        if (type === 'GROUP') {
          return DEFAULT_GROUP_ROW_HEIGHT_PX;
        }
        return nonGroupedHeightGetter(row);
      };
    }
    return nonGroupedHeightGetter;
  }, [viewTab]);

  useEffect(() => {
    services.runs.getCompletedRunSummaries().then((summaries: RunMetadata[]) => {
      const plans = summaries.filter((summary) => summary.procedure_type);
      const projectPlans = filterByProject(plans);
      const filteredPlans = projectPlans.map((summary) => ({
        ...summary,
        projectName: projectUtil.getProjectName(projects, summary.project_id),
      }));
      setCompletedTestRuns(filteredPlans);
      setIsLoading(false);
    });
  }, [filterByProject, projects, services.runs]);

  const displayRuns = useMemo(() => {
    const filteredRows = filterBySearchTerm({
      searchTerm,
      allData: completedTestRuns,
      getStrings: (run: PlanRow) => [run.name, run.code, run.projectName, run.state],
    });
    return filterByField({
      rows: filteredRows,
      fieldName: 'project_id',
      values: selectedProjects,
    });
  }, [searchTerm, completedTestRuns, selectedProjects]);

  const columns: Array<Column<PlanRow>> = useMemo(
    () => [
      {
        key: 'name',
        name: 'Running Plan',
        width: '30%',
        sortable: true,
        renderCell: ({ row }) =>
          RunNameLinkRenderer({
            code: row.code,
            runNumber: row.run_number,
            link: runViewPath(currentTeamId, row._id),
            name: row.name,
            tags: row.run_tags || [],
            globalTags: [],
          }),
      },
      {
        key: 'projectName',
        name: 'Project',
        width: '12%',
        sortable: true,
        renderGroupCell: projectGroupCell,
        renderCell: ({ row }) => ProjectRenderer({ row, setSearchTerm }),
      },
      {
        key: 'state',
        name: 'State',
        renderCell: ({ row }) => renderRunStatus(row.status ? row.status : row.state, false),
      },
      {
        key: 'startTime',
        name: 'Start Time',
        width: '12.5%',
        sortable: true,
        renderCell: ({ row }) => renderDateTime(row.starttime),
      },
      {
        key: 'completedAt',
        name: 'End Time',
        width: '12.5%',
        sortable: true,
        renderCell: ({ row }) => renderDateTime(row.completedAt),
      },
      {
        key: 'participant_user_ids',
        name: 'Participants',
        width: '15%',
        renderCell: ({ row }) => renderParticipants(row.participant_user_ids),
      },
    ],
    [currentTeamId, setSearchTerm]
  );

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && (
        <Grid
          key={projectsFilter?.project.id}
          columns={columns}
          rows={displayRuns}
          rowHeight={rowHeightGetter}
          emptyRowMessage="No matching runs found"
          usedVerticalSpace={PLAN_VERTICAL_PADDING}
          rowGrouping={grouping}
        />
      )}
    </>
  );
};

export default EndedPlansGrid;
