import React, { Fragment, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import SettingsRow from './SettingsRow';
import SettingsRowCell from './SettingsRowCell';
import { useSettings } from '../../contexts/SettingsContext';
import { ProjectUserRowType, UserRoleDropdownOption } from './types';
import { ACCESS, ALL_LEVELS } from '../../lib/auth';
import Button, { BUTTON_TYPES } from '../Button';

const NEW_USER = {
  id: null,
  roles: [],
  isFixed: false,
};

/**
 * This component is to be used by the project users table.
 *
 * @param user - User information such as ID, access level (e.g. Admin, Editor etc.)
 *   and operator roles (e.g. [MD, DMS, ...]).
 *
 * @param isEnabled - If set to false will hide the edit button.
 * @param isNewUser - If set to true will enable all new user fields and save controls.
 * @param onSave - Function that will be called with an updated user object when the row is saved.
 *  onSave is expected to return a Promise.
 * @param onValidate - Function that will be called when the user clicks the add user button to check if
 *   the user already exists.
 * @param onCancel - Function that will be called when user presses the cancel button.
 * @param onDeleteUser - Function that will be called if user clicks on Delete User button.
 * @param isUserRemovalDisabled - If set to true will prevent user from removing themselves.
 * @param existingUsers - Users currently displayed on other rows, used to exclude them from the dropdown list of users.
 * @param availableRoles - List of roles to display in the dropdown.
 * @param selectedUsers
 * @param setUserId
 * @param setUserRoles
 */
const ProjectUserRow = React.memo<ProjectUserRowType>(
  ({ user, isEnabled, isNewUser, onSave, onCancel, onValidate, existingUsers, availableRoles, selectedUsers }) => {
    const [isEditing, setIsEditing] = useState(isNewUser);
    const { users } = useSettings();
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const isMounted = useRef(true);

    const [userId, setUserId] = useState<boolean>(false);
    const [userRoles, setUserRoles] = useState<boolean>(false);

    // Update mounted flag when component is unmounted
    useEffect(
      () => () => {
        isMounted.current = false;
      },
      []
    );

    const usersArray = useMemo(() => {
      if (!users || !users.users) {
        return [];
      }

      return (
        existingUsers &&
        Object.keys(users.users)
          .filter((userId) => !existingUsers.has(userId))
          .map((userId) => {
            return {
              value: userId,
              label: userId,
            };
          })
          .sort((a, b) => a.value.localeCompare(b.value))
      );
    }, [existingUsers, users]);

    // Needs to return an object because of Formik.
    const initialValues = useMemo(() => {
      if (!user) {
        return { user: { ...NEW_USER } };
      }
      // If the user has a workspace role then it can't be removed from here, it has to be removed from the workspace level
      if (user.roles.some((role) => role.isFixed)) {
        user.isFixed = true;
      }
      return { user };
    }, [user]);

    const availableRolesOptions = useMemo(() => {
      if (!availableRoles) {
        return null;
      }

      return availableRoles.map((role) => ({
        value: {
          name: role.name,
          code: role.code,
          id: role.id,
        },
        label: role.name,
      }));
    }, [availableRoles]);

    const availableRolesSelectArray = useCallback(() => {
      if (!availableRolesOptions) {
        return null;
      }

      // Get the workspace level access, if available
      const workspaceRole = user?.roles.filter((roleObject) => roleObject.isFixed)[0];
      // Find the index of the hierarchical access level
      const workspaceRoleIndex = workspaceRole ? ALL_LEVELS.indexOf(workspaceRole.name.toLowerCase() as ACCESS) : 0;
      // Now use existingRoleIndex to index the hierarchical list of roles and only show relevant options
      const availableRoles = ALL_LEVELS.slice(workspaceRoleIndex);

      // Now filter the dropdown options
      return availableRolesOptions
        .filter((role) => {
          return availableRoles.includes(role.label.toLowerCase() as ACCESS);
        })
        .sort((a, b) => a.value.name.localeCompare(b.value.name));
    }, [availableRolesOptions, user]);

    const edit = useCallback(() => {
      setIsEditing(true);
    }, []);

    const modifyUser = useCallback(
      (user) => {
        const payload = {
          id: user.id,
          project_roles: user.roles.map((userRole) => userRole.id),
        };

        onSave &&
          onSave(payload, isNewUser)
            .then(() => {
              if (!isMounted.current) {
                return;
              }

              setIsEditing(false);
            })
            .catch((errors) => {
              if (!isMounted.current) {
                return;
              }

              setErrorMessage(errors);
            });
      },
      [isNewUser, onSave]
    );

    /**
     * Called when the user clicks save from user row.
     */
    const save = useCallback(
      (updated) => {
        // Only submit one role update to the backend, excluding the fixed workspace role
        const fixedRole = user && user.roles.map((role) => role.isFixed && role.name);
        updated.user.roles = updated.user.roles.filter((val) => !fixedRole?.includes(val.name));

        if (updated && selectedUsers && selectedUsers.length) {
          updated.user.id = selectedUsers && selectedUsers[0].id;
        }

        setErrorMessage(null);
        if (isNewUser) {
          onValidate &&
            onValidate(updated.user)
              .then(() => {
                modifyUser(updated.user);
              })
              .catch((errors) => {
                if (!isMounted.current) {
                  return;
                }

                setErrorMessage(errors);
                return;
              });
        } else {
          try {
            modifyUser(updated.user);
          } catch (errors) {
            if (!isMounted.current) {
              return;
            }

            setErrorMessage(errors);
            return;
          }
        }
      },
      [user, isNewUser, onValidate, modifyUser, selectedUsers]
    );

    const setValue = useCallback((path, updated, setFieldValue) => {
      let strippedValue;

      if (Array.isArray(updated)) {
        strippedValue = updated.map((update) => update.value);
      } else {
        strippedValue = updated.value;
      }
      setFieldValue(path, strippedValue);
    }, []);

    const setInitialUser = (values) => {
      if (selectedUsers && selectedUsers?.length > 0) {
        return {
          value: selectedUsers[0].id,
          label: selectedUsers[0].id,
        };
      }
      if (values.user.id) {
        return {
          value: values.user.id,
          label: values.user.id,
        };
      } else {
        return [];
      }
    };

    const mapRoles = (values): UserRoleDropdownOption => {
      let fixedRole;
      let projectRole;

      // If we have multiple roles (workspace and project) then grab the highest one
      values.user.roles.forEach((roleObject) => {
        if (values.user.roles.length > 1 && !roleObject.isFixed) {
          projectRole = {
            label: roleObject.name,
            value: roleObject,
            isFixed: roleObject.isFixed,
          };
        } else {
          fixedRole = {
            label: roleObject.name,
            value: roleObject,
            isFixed: roleObject.isFixed,
          };
        }
      });
      if (projectRole && fixedRole) {
        const projectIndex = ALL_LEVELS.indexOf(projectRole.label.toLowerCase());
        const fixedIndex = ALL_LEVELS.indexOf(fixedRole.label.toLowerCase());
        return projectIndex >= fixedIndex ? projectRole : fixedRole;
      }
      return projectRole || fixedRole;
    };

    const displayRoles = () => {
      const mappedRoles = mapRoles({ user });
      if (user && user.roles) {
        return (
          <div className="my-1" key={mappedRoles?.value?.code}>
            {mappedRoles?.value?.name}
          </div>
        );
      } else {
        return null;
      }
    };

    return (
      <Fragment>
        {!isEditing && (
          <SettingsRow>
            {/* User ID cell */}
            <SettingsRowCell>
              <div className="flex flex-row flex-wrap">
                <div className="py-1.5 px-2 border-2 border-transparent">{user && user.id}</div>
              </div>
              {/* empty div keeps entire row aligned when errors are rendered */}
              {errorMessage && <div className="h-5 mr-1 text-red-700"></div>}
            </SettingsRowCell>
            {/* Access cell */}
            <SettingsRowCell>
              <div className="py-1.5 px-2 border-2 border-transparent">{displayRoles()}</div>
              {/* empty div keeps entire row aligned when errors are rendered */}
              {errorMessage && <div className="h-5 mr-1 text-red-700"></div>}
            </SettingsRowCell>

            {/* Edit button */}
            <SettingsRowCell align="right">
              {isEnabled && (
                <div className="py-1.5">
                  <Button type="tertiary" size="sm" ariaLabel="edit" leadingIcon="pencil-alt" onClick={edit}>
                    Edit
                  </Button>
                </div>
              )}
            </SettingsRowCell>
          </SettingsRow>
        )}

        {isEditing && (
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              save(values);
            }}
            onReset={onCancel}
          >
            {({ values, setFieldValue, handleSubmit, handleReset }) => (
              <Form className="  bg-white" onSubmit={handleSubmit}>
                <div className="">
                  {/* Only available if adding a new user to project. */}
                  {isNewUser && (
                    <div className="w-full flex flex-col mr-2">
                      <label className="field-title">User</label>
                      {setUserId && (
                        <Select
                          classNamePrefix="settings-select react-select"
                          value={setInitialUser(values)}
                          options={usersArray}
                          onChange={(value) => {
                            setValue('user.id', value, setFieldValue);
                            setUserId(true);
                          }}
                          isDisabled={selectedUsers && selectedUsers?.length > 0}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="flex flex-row ">
                  {selectedUsers && selectedUsers?.length > 0 && (
                    <div className="mt-1 w-1/4">
                      <div className="w-full flex flex-col mr-2">
                        <label className="field-title">Workspace Access</label>
                        <div className="py-1.5 px-2 border-2 border-transparent">
                          {selectedUsers[0]?.workspace_role || 'None'}
                        </div>
                      </div>
                    </div>
                  )}

                  {/*Single select user roles cell*/}
                  <div className={`mt-1 ${selectedUsers && selectedUsers.length > 0 ? 'w-3/4' : 'w-full'}`}>
                    {/* Only a field if adding a new user. py-1.5 is used to match react-select inputs */}
                    {isNewUser && (
                      <div className="w-full flex flex-col mr-2">
                        <label className="field-title">Project Access</label>
                        {setUserRoles && (
                          <Select
                            classNamePrefix="settings-select react-select"
                            value={mapRoles(values)}
                            options={availableRolesSelectArray()}
                            onChange={(value) => {
                              setValue('user.roles', [value], setFieldValue);
                              setUserRoles(true);
                              if (selectedUsers && selectedUsers?.length > 0) {
                                setUserId && setUserId(true);
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row gap-x-2 pt-2 justify-end">
                  <div className="flex  h-[32px] ">
                    <Button type={BUTTON_TYPES.SECONDARY} onClick={onCancel}>
                      Cancel
                    </Button>
                  </div>
                  <div className="flex  h-[32px] ">
                    <Button
                      type={BUTTON_TYPES.PRIMARY}
                      isDisabled={!(userId && userRoles)}
                      onClick={() => {
                        save(values);
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Fragment>
    );
  }
);

export default ProjectUserRow;
